var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { DialogActions, DialogBody, DialogContent, DialogSurface, DialogTitle, Input, Label, Button, Spinner, } from "@fluentui/react-components";
import React, { useEffect, useState } from "react";
import { styled } from "styled-components";
import { PoliciesService } from "../PoliciesService";
import { AlertOnRegular } from "@fluentui/react-icons";
const StyledDialogTitle = styled(DialogTitle).withConfig({ displayName: "StyledDialogTitle", componentId: "-1cdesgi" }) `
  align-items: center;
  display: flex;
  padding-bottom: 20px;
`;
const StyledDialogContent = styled(DialogContent).withConfig({ displayName: "StyledDialogContent", componentId: "-fr3zy5" }) `
  display: flex;
  flex-direction: column;
`;
const StyledLabel = styled(Label).withConfig({ displayName: "StyledLabel", componentId: "-1m3mdlr" }) `
  color: #707070;
  margin-bottom: 4px;
`;
const StyledDialogActions = styled(DialogActions).withConfig({ displayName: "StyledDialogActions", componentId: "-2sqqt1" }) `
  padding-top: 20px;
`;
const StyledAlert = styled.div.withConfig({ displayName: "StyledAlert", componentId: "-1e1wxmt" }) `
  display: flex;
  flex-direction: row;
  font-size: 14px;
  background-color: #f5f5f5;
  margin-top: 16px;
  padding: 0px 12px;
  align-items: center;
  border-radius: 10px;
`;
const StyledAlertOnRegular = styled(AlertOnRegular).withConfig({ displayName: "StyledAlertOnRegular", componentId: "-19kjhtb" }) `
  font-size: 24px;
  margin-right: 20px;
`;
const SettingsModal = ({ onClose }) => {
    const [policyHomePage, setPolicyHomePage] = useState("");
    const [isLoading, setIsLoading] = React.useState(false);
    const [disableSave, setDisableSave] = React.useState(false);
    const { GetPolicyHomePage, SetPolicyHomePage } = PoliciesService();
    useEffect(() => {
        setIsLoading(true);
        const getPolicyHomePage = () => __awaiter(void 0, void 0, void 0, function* () {
            setPolicyHomePage(yield GetPolicyHomePage());
            setIsLoading(false);
        });
        getPolicyHomePage();
    }, []);
    const handleHomePageSave = () => __awaiter(void 0, void 0, void 0, function* () {
        try {
            setDisableSave(true);
            yield SetPolicyHomePage(policyHomePage);
            setDisableSave(false);
            onClose("Policies home page link saved successfully");
        }
        catch (e) {
            setDisableSave(false);
            console.error(e);
        }
    });
    return (_jsx(DialogSurface, { children: _jsxs(DialogBody, { children: [_jsx(StyledDialogTitle, { children: "Edit policies home page" }), _jsx(StyledDialogContent, { children: isLoading ? (_jsx(Spinner, { size: "small", label: "We're getting everything ready for you..." })) : (_jsxs(_Fragment, { children: [_jsx(StyledLabel, Object.assign({ htmlFor: "policy-homepage", required: true }, { children: "Home page" })), _jsx(Input, { id: "policy-homepage", placeholder: "Insert policies home page link", size: "large", value: policyHomePage, onChange: (e) => setPolicyHomePage(e.target.value) }), _jsxs(StyledAlert, { children: [_jsx(StyledAlertOnRegular, {}), _jsx("p", { children: "Ensure the homepage link is sourced from the webpart you want the link to direct to." })] })] })) }), _jsxs(StyledDialogActions, { children: [_jsx(Button, Object.assign({ appearance: "secondary", onClick: () => onClose() }, { children: "Cancel" })), _jsx(Button, Object.assign({ appearance: "primary", disabled: disableSave, onClick: handleHomePageSave }, { children: "Save" }))] })] }) }));
};
export default SettingsModal;
