import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import * as React from "react";
import styled from "styled-components";
import { Title } from "../../../Nav.styles";
import { FaAngleDown, FaAngleUp } from "react-icons/fa";
import { AudienceTargeting } from "../../AudienceTargeting/AudienceTargeting";
import { Field, Input, Switch } from "@fluentui/react-components";
const ContentMenuWrapper = styled.div.withConfig({ displayName: "ContentMenuWrapper", componentId: "-292ju3" }) `
  padding: 0;
`;
const LinkViewer = styled.div.withConfig({ displayName: "LinkViewer", componentId: "-1ct77pa" }) `
  width: 100%;
  cursor: pointer;
`;
const NodeActions = styled.fieldset.withConfig({ displayName: "NodeActions", componentId: "-12fzgm" }) `
  justify-content: flex-end;
  border: 0;
  padding-block: 0.5rem;
`;
export const ContentMenu = (props) => {
    var _a, _b;
    const [node, setNode] = React.useState(props.node);
    const [showInputName, setShowInputName] = React.useState(false);
    const [checked, setChecked] = React.useState(props.node && props.node.link ? props.node.link.openInNewTab : false);
    const [isContentVisible, setIsContentVisible] = React.useState(true);
    const extractNodeValue = (node) => {
        let value = "";
        node.labels.forEach((l) => {
            if (l) {
                if (props.currentLanguage &&
                    l.languageCode === props.currentLanguage.languageCode) {
                    value = l.value;
                }
                else {
                    if (l.languageCode === "default") {
                        value = l.value;
                    }
                }
            }
        });
        return value;
    };
    function updateText(n, text) {
        n.labels.forEach((t) => {
            if (t.languageCode === props.currentLanguage.languageCode) {
                t.value = text;
            }
        });
        return n.labels;
    }
    function updateNodeName(e) {
        const newNode = Object.assign(Object.assign({}, node), { labels: updateText(node, e.target.value) });
        setNode(newNode);
        props.updateNodesAndMenu(newNode, props.node);
        setShowInputName(false);
    }
    function updateNodeLink(e) {
        const newNode = Object.assign(Object.assign({}, node), { link: {
                url: e.target.value,
                openInNewTab: node.link.openInNewTab,
            } });
        setNode(newNode);
        props.updateNodesAndMenu(newNode, props.node);
    }
    const updateCheckedOpenInNewTab = React.useCallback((ev) => {
        const newNode = Object.assign(Object.assign({}, node), { link: {
                url: node.link.url,
                openInNewTab: ev.currentTarget.checked,
            } });
        setChecked(ev.currentTarget.checked);
        props.updateNodesAndMenu(newNode, props.node);
        setNode(newNode);
    }, [setChecked]);
    const toggleContentVisibility = () => {
        setIsContentVisible(!isContentVisible);
    };
    return (_jsxs(ContentMenuWrapper, { children: [_jsxs(Title, Object.assign({ className: "lt-style" }, { children: ["Content", " ", !isContentVisible && (_jsx(FaAngleDown, { onClick: toggleContentVisibility, style: {
                            position: "absolute",
                            right: "2rem",
                            marginLeft: "10px",
                            cursor: "pointer",
                        } })), isContentVisible && (_jsx(FaAngleUp, { onClick: toggleContentVisibility, style: {
                            position: "absolute",
                            right: "2rem",
                            marginLeft: "10px",
                            cursor: "pointer",
                        } })), " "] })), isContentVisible && (_jsxs(_Fragment, { children: [_jsxs("fieldset", Object.assign({ className: "lt-style" }, { children: [_jsx("label", { children: "Name" }), showInputName ? (_jsx(Field, { children: _jsx(Input, { autoFocus: true, onBlur: updateNodeName, type: "text" }) })) : (_jsx(Field, { children: _jsx(Input, { onClick: () => {
                                        setShowInputName(true);
                                    }, value: node ? extractNodeValue(node) : "", type: "text", disabled: !node }) }))] })), _jsxs("fieldset", Object.assign({ className: "lt-style" }, { children: [_jsx("label", { children: "URL" }), _jsx(LinkViewer, { children: _jsx(Field, { children: _jsx(Input, { onBlur: updateNodeLink, type: "text", disabled: !node, defaultValue: node && node.link && ((_a = node.link) === null || _a === void 0 ? void 0 : _a.url) !== "" ? node.link.url : null, placeholder: node && node.link && ((_b = node.link) === null || _b === void 0 ? void 0 : _b.url) !== "" ? null : "Add Link Here" }) }) })] })), _jsxs("fieldset", Object.assign({ className: "lt-style" }, { children: [_jsx("label", { children: "Open link in new tab?" }), _jsx(Switch, { checked: checked, disabled: !node, onChange: updateCheckedOpenInNewTab })] })), node && (_jsxs("fieldset", Object.assign({ className: "lt-style" }, { children: [_jsx("label", { children: "Audience Targeting" }), _jsx(AudienceTargeting, { updateNode: (newNode) => {
                                    setNode(newNode);
                                    props.updateNodesAndMenu(newNode, props.node);
                                }, node: node })] })))] }))] }));
};
